import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyOneMillionTemplate from "../modules/caseStudy/CaseStudyOneMillion.template"

const CaseStudyOneMillionFollowers = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study One Million Followers" />
      <CaseStudyOneMillionTemplate image={data.caseStudyOneMillion.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyOneMillion: allFile(
      filter: { relativeDirectory: { eq: "caseStudyOneMillion" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyOneMillionFollowers
