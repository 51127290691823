import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyOneMillionTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        smallImage={true}
        title="One Million Followers"
        description="A well-crafted, strategic Hook Point puts a new author and speaker on the map"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              Hook Point agency founder, author and speaker Brendan Kane{" "}
              <span>rose above the noise</span> in a crowded, late-stage market
              with little previous presence
            </li>
            <li>
              Turned his book title into a Hook Point to grab{" "}
              <span>
                instant attention and get audiences talking in business and
                beyond,
              </span>{" "}
              while continuing to use Hook Points to drive business, sales and
              <span>speaking opportunities</span>
            </li>
            <li>
              Resulted in <span>70,000+ book sales</span> globally, dozens of
              global speaking and event appearances, and{" "}
              <span>hundreds of podcast and media interviews,</span> as well as
              international private clients and
              <span>millions of dollars in revenue</span>
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-planet-home"}
        text="We’d love to tell you more about how our Founder was our own case study. "
        subText="Let’s work together"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyOneMillionTemplate
